import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "poster", "real", "element"]
  connect() {
    setTimeout(() => {

      // if (this.elementTarget.paused) { this.hide() }
      // else {  }
    },500 )
  }
  show_and_play() {
    this.show();

    this.elementTarget.firstChild.play()
    ga(`${window.GOOGLE_UA_NAME}.send`, 'event', 'Videos', 'play', '' );

  }
  show() {

    this.posterTarget.classList.add('d-none')
    this.realTarget.classList.remove('d-none')
  }
  hide() {

    this.posterTarget.classList.remove('d-none')
    this.realTarget.classList.add('d-none')
  }


}
