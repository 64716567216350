import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [  "intakeSection", "justTakeMyEmailSection",
    "justTakeMyEmailTeaserButton",
    "justTakeMyEmailForm",
    "take-email-button",
    "somethingElseInput",
    "anotherPlatformInput", "customerInfo"]

  connect() {
    this.justTakeMyEmail
  }

  highlight_section(x) {
    if(x==="intake") {
      this.intakeSectionTarget.classList.remove("disabled")
      this.justTakeMyEmailFormTarget.classList.add("hidden")
      this.justTakeMyEmailTeaserButtonTarget.classList.remove("disabled")
      this.customerInfoTarget.classList.remove("hidden")
    } else {
      this.justTakeMyEmailTeaserButtonTarget.classList.add("disabled")
      this.justTakeMyEmailFormTarget.classList.remove("hidden")
      this.justTakeMyEmailSectionTarget.classList.remove("disabled")
      this.intakeSectionTarget.classList.add("disabled")
      this.customerInfoTarget.classList.add("hidden")

    }
  }

  click_justTakeMyEmail() {
    this.highlight_section("just-take-my-email")
  }
  click_intakeSection() {
    this.highlight_section("intake")
  }

  click_somethingElseChoice(event) {
    if(event.target.checked) {
      this.somethingElseInputTarget.classList.remove("not-there")
    } else {
      this.somethingElseInputTarget.classList.add("not-there")
    }
  }

  click_anotherPlatformChoice(event) {
    if(event.target.checked) {
      this.anotherPlatformInputTarget.classList.remove("not-there")
    } else {
      this.anotherPlatformInputTarget.classList.add("not-there")
    }
  }
}
