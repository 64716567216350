import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["radioButton"];

  connect() {
  }

  click_variantBox(event) {
    const collection = document.getElementsByClassName("choice-column")
    Array.from(collection).forEach(  (elem) => {
      elem.classList.remove("alert-info")
    });

    event.target.closest(".choice-column").classList.add("alert", "alert-info")
    this.radioButtonTarget.checked = true;

    try {
      ga(`${window.GOOGLE_UA_NAME}.send`, 'event', 'variant-selection', `choose ${this.element.dataset.productName}`, this.element.dataset.variantName );
    } catch(e) {}
    document.getElementById("variant-chooser").querySelector("input[type=submit]").disabled = false;
  }
}

