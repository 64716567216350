import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="thing"

export default class extends Controller {

  connect() {
    console.log("xyz connect")



  }
}
