import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="thing"
export default class extends Controller {
  static outlets  = ["xyz"]
  connect() {
    console.log("thing connect", this.xyzOutlet);
    console.log("this.hasXyzOutlet", this.hasXyzOutlet)
    console.log("thing cnotroller - the xyz outlet is", this.xyzOutlet);
  }
}
