const rollInEffect = () => {

  const callback = (arr, appearOnScroll) => {
    arr.forEach((entry) => {
      if (!entry.isIntersecting) {
        return
      } else {
        entry.target.classList.add(`visible`);
        appearOnScroll.unobserve(entry.target)

      }
    })
  }
  const appearOnScroll = new IntersectionObserver( callback, {
    root: null,
    rootMargin: '0px',
    threshold: 0
  })

  let targets = document.querySelectorAll('.roll-in');
  targets.forEach((target) => {
    appearOnScroll.observe(target);
  })
}

export default rollInEffect;