/* eslint no-console:0 */

import "@hotwired/turbo-rails"
import { Turbo } from "@hotwired/turbo-rails"

import * as ActiveStorage from "@rails/activestorage"


import './hello-world-bundle'
import '../controllers'

import 'bootstrap'
import videojs from '@mux/videojs-kit';
import '@mux/videojs-kit/dist/index.css';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles


Turbo.start();

// EFFECTS & POLYFILLS

import softFadeEffect from '../effects/softfade_effect'
import popInEffect from '../effects/pop_in_effect'
import rollInEffect from '../effects/roll_in_effect'
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

window.addEventListener('turbo:load', function() {
  AOS.init({
    // Global settings:
    disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
    initClassName: 'aos-init', // class applied after initialization
    animatedClassName: 'aos-animate', // class applied on animation
    useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 120, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 400, // values from 0 to 3000, with step 50ms
    easing: 'ease', // default easing for AOS animations
    once: false, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
  });
});

window.addEventListener('turbo:load', function() {
  softFadeEffect();
  popInEffect();
  rollInEffect();
})



import ReactOnRails from 'react-on-rails';
ReactOnRails.setOptions({ turbo: true })
import HelloWorld from '../bundles/HelloWorld/components/HelloWorld';
import WixOrShopifyWidget from '../components/marketing/WixOrShopifyWidget'
import WixOrWordpressWidget from '../components/marketing/WixOrWordpressWidget'
import ShopifyOrBlankWidget from '../components/marketing/ShopifyOrBlankWidget'

import '@fortawesome/fontawesome-free'
import ShopifyOrMegentoWidget from "../components/marketing/ShopifyOrMegentoWidget";
import WixOrSquareSpaceWidget from "../components/marketing/WixOrSquareSpaceWidget";
import ContractTypeChooser from "../components/marketing/ContractTypeChooser";
import NextJsAnimationFlipper from "../components/marketing/NextJsAnimationFlipper";
import HerokuPipelineEstimateBuilder from "../components/marketing/heroku_pipeline_estimator/HerokuPipelineEstimateBuilder";
import Logo from "../components/basic_elements/logo/Logo"


ReactOnRails.register({
  HelloWorld,
  WixOrShopifyWidget,
  WixOrWordpressWidget,
  ShopifyOrBlankWidget,
  ShopifyOrMegentoWidget,
  WixOrSquareSpaceWidget,
  ContractTypeChooser,
  NextJsAnimationFlipper,
  HerokuPipelineEstimateBuilder,
  Logo
});
