import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkout"
export default class extends Controller {
  static targets = ["submitPaymentForm",
    "submitButton", "cardErrors", "cardholderName", "cardResult"];

  onChangeHandler(event)  {
    // scope changes to event context here -- why
    var displayError = document.getElementById('card-errors');
    if (event.error) {
      this.cardErrorsTarget.textContent = event.error.message;
    } else {
      this.cardErrorsTarget.textContent = '';
    }

    this.submitButtonTarget.removeAttribute('disabled')
  }

  connect() {
    if (typeof (Stripe) == "undefined") {
      console.error("Stripe is not loaded");
      return;
    }

    // stripe JS
    var stripe = Stripe(VERSO.__stripe_pk);
    var elements = stripe.elements();


    const submitPaymentForm = (payment_method_id) => {
      console.log("submitPaymentForm")

      // Insert the token ID into the form so it gets submitted to the server
      var form = this.submitPaymentFormTarget;
      var hiddenInput = document.createElement('input');
      hiddenInput.setAttribute('type', 'hidden');
      hiddenInput.setAttribute('name', 'checkout[stripeMethodId]');
      hiddenInput.setAttribute('value', payment_method_id);
      form.append(hiddenInput);
      // Submit the form
      form.submit();
      this.submitButtonTarget.attr("disabled", true);
    }

    const stripeSubmitInteraction = (event) => {
      console.log("stripeSubmitInteraction")
      event.preventDefault();
      stripe.confirmCardPayment(VERSO.__stripe_payment_intent_client_secret, {
        payment_method: {
          card: card,
          billing_details: {
            name: this.cardholderNameTarget.value
          }
        },
        setup_future_usage: 'off_session'
      }).then(stripeAfterInteraction.bind(this));
      return false;
    }

    const stripeAfterInteraction = (result) => {
      console.log("stripeAfterInteraction")

      if (result.error) {
        // Show error to your customer
        console.log("ERROR", result.error.message);
      } else {
        if (result.paymentIntent.status === 'succeeded') {
          this.cardResultTarget.textContent = "Your card was saved! One moment please...";
          submitPaymentForm(result.paymentIntent.payment_method)
        }
      }
    }

    if (this.submitPaymentFormTarget.length > 0) {
      var card = elements.create('card');
      card.mount('#card-element');
      card.addEventListener('change', this.onChangeHandler.bind(this), false);
      this.submitPaymentFormTarget.addEventListener('submit', stripeSubmitInteraction.bind(this), false);
    }
  }
}
