import { Controller } from "@hotwired/stimulus"
const slideDown = elem => elem.style.height = `${elem.scrollHeight}px`;


// Connects to data-controller="invoice"
export default class extends Controller {
  static targets = ['invoiceGatewayForm', 'payThisInvoice', 'payAnotherAmount',
    'payNowLink', 'paymentChoiceThisInvoiceOnly',
    'payNowSection', 'thisInvoiceOnlyRadio',
    'anotherAmountRadio'];

  connect() {
    console.log("invoice controller connected")

   const resetRadioSelection = () => {
     let selected = this.invoiceGatewayFormTarget.querySelector('input[name="payment_choice"]:checked').value;

     if (selected == 'this_invoice_only') {
       this.payThisInvoiceTarget.removeAttribute('disabled')
       this.payAnotherAmountTarget.setAttribute('disabled','disabled')
     } else {
       this.payAnotherAmountTarget.removeAttribute('disabled')
     }
   }

   this.anotherAmountRadioTarget.addEventListener('change', resetRadioSelection);
   this.thisInvoiceOnlyRadioTarget.addEventListener('change', resetRadioSelection);

    this.payNowLinkTarget.addEventListener("click", (e) => {
      e.preventDefault(); e.stopPropagation();
      this.payNowSectionTarget.setAttribute('style', 'display: block');
      this.payNowLinkTarget.setAttribute('style', 'display: none');
      this.payThisInvoiceTarget.setAttribute('checked', 'checked');
      this.payNowSectionTarget.scrollIntoView(false)
      console.log("this.payNowSectionTarget.getBoundingClientRect().top", this.payNowSectionTarget.getBoundingClientRect().top)

      window.scrollTo({top: this.payNowSectionTarget.getBoundingClientRect().bottom + 100});
    })
  }
}
