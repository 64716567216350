import React, { useState, useEffect } from 'react'
import Arrow from './Arrow'
import Dot from './Dot'
import RightArrow from './RightArrow'

const Logo = (props) => {
  const  [mode, setMode] = useState('animation')
  // const  [focus, setFocus] = useState(0)
  const [animationStep, setAnimationStep] = useState(4)

  useEffect(() => {
    goNextStep()
  }, [])

  const goNextStep = () => {
    const newStep = (animationStep > 3) ? 0 : animationStep + 1;
    setAnimationStep(newStep)
  }

  useEffect(() => {
    setTimeout(() => {
      goNextStep()
    }, 1500)
  }, [animationStep])

  let focus;

  switch(animationStep) {
    case 0: focus = ''; break;
    case 1: focus = 'red';  break;
    case 2: focus = 'green'; break;
    case 3: focus = 'refactor'; break;
    case 4: focus = ''; break;
  }

  //
  const chooseStep = (step_name) => {
    // console.log('choose step', step_name)
    // setFocus(step_name)
  }

  return (
    <div className={"rgr-logo"} style={props.style}>
    <Dot function={"red"} chooseStep={chooseStep} focusedStep={focus === 'red'}/>
    <Dot function={"green"} chooseStep={chooseStep} focusedStep={focus === 'green'}/>
    <Dot function={"refactor"} chooseStep={chooseStep} focusedStep={focus === 'refactor'}/>
    <Arrow position={1} />
    <Arrow position={2} />
    <Arrow position={3} />
    <RightArrow />

  </div>)

}

export default Logo;
